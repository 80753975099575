// store all commonly use variable here

export const siteWidth = "1920px";
export const innerWidth = "1680px";
export const mainRed = `#C81D29`;
// export const navyBlue = `#253D5F`;
export const lightNavyBlue = `#24466C`;
export const mainWhite = `#FFFFFF`;
export const mainBlack = `#000000`;
export const mainBlue = `#1078AA`;
export const lightBlue = `#00A8E8`;
export const highlight = `#86cecf`;

// hover trasition for consistency
export const transHover = `all 0.2s ease-out`;

// media queries for devices
export const screen = {
  xsmall: `(min-width: 413px)`, // larger phones
  small: `(min-width: 650px)`, // tablet vertical
  medium: `(min-width: 1160px)`, // tablet horizontal
  large: `(min-width: 1278px)`, // laptop and regular size desktop
  minilarge: `(min-width: 1278px) and (max-height: 690px)`, // laptop with short height
  xlarge: `(min-width: 1690px)`, // huge size desktop
  withCursor: `not all and (pointer: coarse)`, // device with cursor. not touch screen devices
};

// regext for email validation
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

// all fonts
export const mainFont = `helvetica, sans-serif`;
