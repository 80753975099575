import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import {
  mainWhite,
  highlight,
  transHover,
  screen,
} from "../../components/common/variables";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
  FaYoutube,
  FaImdb,
  FaVimeoV,
} from "react-icons/fa";
import IconShowcast from "../../images/svg/showcast-icon.svg";

const Wrapper = styled.footer`
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: ${mainWhite};
  @media ${screen.small} {
    position: relative;
    z-index: 9;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 28px;
    padding-bottom: 28px;
  }
  @media ${screen.medium} {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media ${screen.xlarge} {
    padding-left: 0;
    padding-right: 0;
  }

  .account-list {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;

    &__each {
      margin: 15px 15px;
      @media ${screen.small} {
        margin: 0 30px;
      }
      a {
        transition: ${transHover};
        font-size: 1.1rem;
        @media ${screen.small} {
          ont-size: 1.5rem;
        }
        @media ${screen.withCursor} {
          &:hover {
            color: ${highlight};
          }
        }
        svg {
        }
      }

      &--showcast {
        a {
          svg {
            min-width: 19px;

            #sc-fill {
              transition: ${transHover};
              @media ${screen.withCursor} {
                &:hover {
                  fill: ${highlight};
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Footer = () => {
  const { external_account } = useStaticQuery(dataQuery).content.data;

  return (
    <Wrapper>
      <ul className="account-list">
        {external_account.map((item, i) => (
          <li className="account-list__each" key={i}>
            <a href={item.full_url} target="_blank" rel="noreferrer">
              {item.account === "Facebook" && <FaFacebookF />}
              {item.account === "Vimeo" && <FaVimeoV />}
              {item.account === "LinkedIn" && <FaLinkedinIn />}
              {item.account === "Youtube" && <FaYoutube />}
              {item.account === "IMDb" && <FaImdb />}
              {item.account === "Twitter" && <FaTwitter />}
              {item.account === "Instagram" && <FaInstagram />}
            </a>
          </li>
        ))}
        <li className="account-list__each account-list__each--showcast">
          <a
            href="https://app.showcast.com.au/profile/nickbolton"
            target="_blank"
            rel="noreferrer"
          >
            <IconShowcast />
          </a>
        </li>
      </ul>
    </Wrapper>
  );
};

export default Footer;

const dataQuery = graphql`
  {
    content: prismicGeneralDetails {
      data {
        external_account {
          full_url
          account
        }
      }
    }
  }
`;
