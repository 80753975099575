import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  mainWhite,
  highlight,
  mainBlack,
  transHover,
  siteWidth,
  innerWidth,
  screen,
} from "../../components/common/variables";
import { IoIosMenu, IoMdClose } from "react-icons/io";
import { TouchScrollable } from "react-scrolllock";
import Logo from "../../images/svg/logo.svg";
import { Link } from "gatsby";

const Wrapper = styled.nav`
  background: ${mainWhite};
  box-shadow: 0 4px 10px -5px rgba(0, 0, 0, 0.2);
  z-index: 10;
  position: fixed;
  max-width: ${siteWidth};
  width: 100%;
  @media ${screen.large} {
  }

  .inner-wrapper {
    display: none;
    max-width: ${innerWidth};
    margin: 0 auto;
    @media ${screen.medium} {
      padding: 47px 50px;
      display: block;
    }
    @media ${screen.xlarge} {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .brand {
    display: none;
    @media ${screen.medium} {
      display: block;
      transition: all 0.2s ease-out;
      padding: 47px 0 7px 0;
      margin-top: ${(props) => (props.hideLogo ? "-111px" : "0px")};
    }
    @media ${screen.xlarge} {
      padding: 47px 0 17px 0;
      margin-top: ${(props) => (props.hideLogo ? "-128px" : "0px")};
    }
    svg {
      width: 750px;
      display: block;
      margin: 0 auto;
      @media ${screen.medium} {
        width: 500px;
      }
      @media ${screen.xlarge} {
        width: 580px;
      }
    }
  }

  .nav-list {
    display: flex;
    align-items: center;
    justify-content: center;

    &__item {
      margin: 0 24px;
      @media ${screen.xlarge} {
        margin: 0 34px;
      }

      svg {
        width: 20px;
        margin-right: 3px;
        vertical-align: middle;
      }

      p {
        color: ${mainBlack};
        display: inline-block;
        font-size: 1.3rem;
        transition: ${transHover};
        @media ${screen.xlarge} {
          font-size: 1.4rem;
        }
      }

      a {
        color: ${mainBlack};
        font-size: 1.2rem;
        font-weight: 400;
        transition: ${transHover};
        @media ${screen.xlarge} {
          font-size: 1.3rem;
        }

        @media ${screen.withCursor} {
          &:hover {
            color: ${highlight};
          }
        }
      }
    }
  }

  .active {
    color: ${highlight} !important;
  }

  .sm-navbar {
    display: block;
    position: fixed;
    z-index: 10;
    background: ${mainWhite};
    height: 60px;
    width: 100%;
    box-shadow: 0 4px 10px -5px rgba(0, 0, 0, 0.2);
    @media ${screen.small} {
      height: 82px;
    }
    @media ${screen.medium} {
      display: none;
    }

    .togge-icon {
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
      svg {
        color: ${mainBlack};
        font-size: 2.4rem;
        @media ${screen.small} {
          font-size: 2.8rem;
        }
      }
    }

    .logo {
      position: absolute;
      left: 25px;
      top: 50%;
      transform: translateY(-50%);
      @media ${screen.small} {
        left: 37px;
      }
      svg {
        width: 168px;
        @media ${screen.small} {
          width: 286px;
        }
      }
    }
  }

  .overlay-menu {
    background: ${mainWhite};
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: scroll;
    transition: ${transHover};
    @media ${screen.medium} {
      display: none;
    }
    &__nav-list {
      padding-top: 118px;
      @media ${screen.small} {
        padding-top: 150px;
      }
      .item {
        text-align: center;
        margin: 0 0 25px 0;
        @media ${screen.small} {
          margin: 0 0 37px 0;
        }
        a {
          font-size: 1.26rem;
          @media ${screen.small} {
            font-size: 1.3rem;
          }
        }
      }
    }
  }
`;

const Nav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const userScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", userScroll, { passive: false });

    return () => {
      window.removeEventListener("scroll", userScroll);
    };
  }, []);

  const hideLogo = scrollY > 125;

  return (
    <Wrapper hideLogo={hideLogo}>
      <div className="brand">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className="sm-navbar">
        <div className="togge-icon" onClick={toggleMenu}>
          {isMenuOpen ? <IoMdClose /> : <IoIosMenu />}
        </div>
        <div className="logo">
          <Link to="/">
            <Logo />
          </Link>
        </div>
      </div>
      <TouchScrollable>
        <div
          className="overlay-menu"
          style={{
            transform: isMenuOpen ? `translateX(0)` : `translateX(100%)`,
          }}
        >
          <ul className="overlay-menu__nav-list">
            <li className="item">
              <Link to="/" activeClassName="active">
                HOME
              </Link>
            </li>
            <li className="item">
              <Link to="/bio/" activeClassName="active">
                BIO
              </Link>
            </li>
            <li className="item">
              <Link to="/work/" activeClassName="active">
                WORK
              </Link>
            </li>

            <li className="item">
              <Link to="/gallery/" activeClassName="active">
                GALLERY
              </Link>
            </li>
            <li className="item">
              <Link to="/news/" activeClassName="active">
                NEWS
              </Link>
            </li>
            <li className="item">
              <Link to="/contact/" activeClassName="active">
                CONTACT
              </Link>
            </li>
          </ul>
        </div>
      </TouchScrollable>

      <div className="inner-wrapper">
        <ul className="nav-list">
          <li className="nav-list__item">
            <Link to="/" activeClassName="active">
              HOME
            </Link>
          </li>
          <li className="nav-list__item">
            <Link to="/bio/" activeClassName="active">
              BIO
            </Link>
          </li>
          <li className="nav-list__item">
            <Link to="/work/" activeClassName="active">
              WORK
            </Link>
          </li>

          <li className="nav-list__item">
            <Link to="/gallery/" activeClassName="active">
              GALLERY
            </Link>
          </li>
          <li className="nav-list__item">
            <Link to="/news/" activeClassName="active">
              NEWS
            </Link>
          </li>
          <li className="nav-list__item">
            <Link to="/contact/" activeClassName="active">
              CONTACT
            </Link>
          </li>
        </ul>
      </div>
    </Wrapper>
  );
};

export default Nav;
