import React, { useState, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import "../css/normalize.css";
import "../css/global.css";
import {
  mainFont,
  mainBlack,
  siteWidth,
  mainWhite,
  screen,
} from "../components/common/variables";
import styled from "styled-components";
import Nav from "../components/nav";
import Footer from "../components/footer";

const Wrapper = styled.div`
  color: ${mainBlack};
  font-family: ${mainFont};
  max-width: ${siteWidth};
  margin: 0 auto;
  background: ${mainWhite};
  min-height: 100vh;
  box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.15),
    5px 0 5px -5px rgba(0, 0, 0, 0.15);

  #page-subwrapper {
    min-height: 100vh;
    @media ${screen.xlarge} {
      min-height: calc(100vh - 80px);
    }
  }
  a {
    color: ${mainBlack};
  }
`;
const Layout = ({ children }) => {
  const [isHomePage, setIsHomePage] = useState(true);

  useLayoutEffect(() => {
    if (typeof window !== "undefined" && window.location.pathname === "/") {
      setIsHomePage(true);
    } else {
      setIsHomePage(false);
    }
  }, []);
  return (
    <Wrapper>
      <Nav />
      <div id="page-subwrapper"> {children}</div>
      {!isHomePage && <Footer />}
    </Wrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
